import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom/client';  // Figyelj, itt 'react-dom/client'-et importálunk
import './index.css';  // CSS fájl importálása
import App from './App';

// Itt hozunk létre egy root elemet a React 18 createRoot használatával
const root = ReactDOM.createRoot(document.getElementById('root'));

// Az alkalmazás renderelése a React 18 createRoot metódussal
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
