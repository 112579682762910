import React, { useState } from 'react';
import { ethers } from 'ethers';
import './App.css';  // Extra styles
import CreateTokenABI from './CreateToken.json';
import 'bootstrap/dist/css/bootstrap.min.css';  // Bootstrap CSS import
import 'bootstrap/dist/js/bootstrap.bundle.min.js';  // Bootstrap JS import (includes Popper.js)
import BaseLogo from './base-logo.png'; // Import Base logo

// Pinata API configuration
const pinataApiKey = '509f5cca84c1eb02f26b';
const pinataSecretApiKey = '3729381aad8ce3865da64620a44961e26aa2534f3595d867f2968211c084569e';

const CONTRACT_ADDRESS = "0x8B5E3Da521b017a2eEDA60E02CE543B5154BFC37";

function App() {
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [totalSupply, setTotalSupply] = useState(0);
  const [status, setStatus] = useState("");
  const [tokenImage, setTokenImage] = useState(null);
  const [imageHash, setImageHash] = useState("");

  const handleFileChange = (e) => {
    setTokenImage(e.target.files[0]);
  };

  const uploadImageToPinata = async (imageFile) => {
    const formData = new FormData();
    formData.append('file', imageFile);

    const metadata = JSON.stringify({
      name: 'Token Logo',
    });
    formData.append('pinataMetadata', metadata);

    const response = await fetch('https://api.pinata.cloud/pinning/pinFileToIPFS', {
      method: 'POST',
      headers: {
        'pinata_api_key': pinataApiKey,
        'pinata_secret_api_key': pinataSecretApiKey
      },
      body: formData
    });

    const result = await response.json();
    return result.IpfsHash;
  };

  const handleCreateToken = async () => {
    if (!window.ethereum) {
      setStatus("MetaMask is required to continue.");
      return;
    }

    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(CONTRACT_ADDRESS, CreateTokenABI.abi, signer);

      const adjustedTotalSupply = ethers.utils.parseUnits(totalSupply.toString(), 18);

      // Upload image to Pinata (if present)
      let imageURI = "";
      let transactionFee = "0.00042"; // Default fee
      if (tokenImage) {
        setStatus("Uploading image...");
        const hash = await uploadImageToPinata(tokenImage);
        imageURI = `ipfs://${hash}`;
        transactionFee = "0.00084"; // Extra fee if an image is present
        setImageHash(hash);
      }

      const tx = await contract.createToken(name, symbol, adjustedTotalSupply, imageURI, {
        value: ethers.utils.parseEther(transactionFee),
      });

      setStatus("Transaction sent, awaiting confirmation...");
      await tx.wait();
      setStatus(`Token successfully created! ${imageHash ? `Image Hash: ${imageHash}` : ""}`);
    } catch (error) {
      setStatus(`An error occurred: ${error.message}`);
    }
  };

  return (
    <div className="content-wrapper">
      {/* Base logo and text */}
      <div className="base-logo-section">
        <img src={BaseLogo} alt="Base Logo" />
        <p>Available on the Base network</p>
      </div>

      {/* Main content */}
      <div className="card p-4 shadow-lg" style={{ maxWidth: '500px', width: '100%' }}>
        <h1 className="text-center mb-4">Create Your Meme Token</h1>
        <div className="form-group mb-3">
          <label className="form-label">Token Name</label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="e.g., MyMemeToken"
          />
        </div>
        <div className="form-group mb-3">
          <label className="form-label">Token Symbol</label>
          <input
            type="text"
            className="form-control"
            value={symbol}
            onChange={(e) => setSymbol(e.target.value)}
            placeholder="e.g., MMT"
          />
        </div>
        <div className="form-group mb-4">
          <label className="form-label">Total Supply</label>
          <input
            type="number"
            className="form-control"
            value={totalSupply}
            onChange={(e) => setTotalSupply(e.target.value)}
            placeholder="e.g., 1000000"
          />
        </div>

        {/* Image upload field */}
        <div className="form-group mb-4">
          <label className="form-label">Token Image (optional)</label>
          <input type="file" className="form-control" onChange={handleFileChange} />
        </div>

        <button className="btn btn-primary w-100" onClick={handleCreateToken}>
          Create Token
        </button>
        <p className="mt-3 text-center text-muted">{status}</p>

        {/* Accordion - Pricing Information */}
        <div className="accordion mt-4" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingPricing">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePricing" aria-expanded="true" aria-controls="collapsePricing">
                Pricing Information
              </button>
            </h2>
            <div id="collapsePricing" className="accordion-collapse collapse show" aria-labelledby="headingPricing" data-bs-parent="#accordionExample">
              <div className="accordion-body">
              The fee for creating token addresses is 0.00042 ETH. If an image is uploaded, the transaction fee will be 0.00084 ETH. Gas fees are variable but generally around 0.03 USD.
              </div>
            </div>
          </div>

          {/* Accordion - Legal Disclaimer */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingLegal">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseLegal" aria-expanded="false" aria-controls="collapseLegal">
                Legal Disclaimer and Liability Waiver
              </button>
            </h2>
            <div id="collapseLegal" className="accordion-collapse collapse" aria-labelledby="headingLegal" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                This website helps create simple meme tokens. We only provide the technology – you are fully responsible for the tokens you create and how they are used. We are not liable for any financial, legal, or other consequences that may result from your tokens. By using this platform, you agree that you create and use tokens at your own risk and are responsible for complying with the laws in your region. We do not provide financial, legal, or investment advice, and anything you do here is at your own risk.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
